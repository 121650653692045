export default {
  init(  ){
    const consentBanner = document.querySelectorAll( '.cc-compliance>a' );

    consentBanner.forEach( button => {
      button.addEventListener( 'click', e => {
        window.location.reload();
        e.preventDefault;
      } );
    } );
  }
}